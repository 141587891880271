import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  Formik, Form, Field, FieldArray,
} from 'formik';
import * as Yup from 'yup';
import Switch from 'rc-switch';
import { actionCreators } from '../../store/modules/ActionType';
import date from '../../utils/date';
import ImageUpload from '../../controls/ImageUpload';
import DeleteButton from '../../controls/DeleteButton';
import SelectField, { Convert } from '../../controls/SelectField';
import {
  DataType, IntegrationType, ConvertToOptions, Delay,
} from '../../utils/enum';
import BackButton from '../../controls/BackButton';
import SaveButton from '../../controls/SaveButton';
import helper from '../../utils/helper';
import DataTypeField from '../../controls/DataTypeField';
import OperatorField from '../../controls/OperatorField';
import confirm, { NoCancel } from '../../utils/confirm';

class ActionTypeEdit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      section: 'fields',
      template: {
        name: '',
        description: '',
        delayInHours: null,
        integrationSettings: {
          apiUrl: '',
          authUrl: '',
          clientId: '',
          clientSecret: '',
          username: '',
          password: '',
          tenantName: '',
          tenantApiKey: '',
          profileId: '',
        },
        fields: [],
        conditions: [],
        imageId: '',
      },
    };
    this.form = React.createRef();
  }

  componentDidMount() {
    const { id, requestGet } = this.props;

    if (id) {
      requestGet(id);
    }
  }

  handleSave(values) {
    const { id, requestPost, requestPut } = this.props;

    if (id) {
      requestPut(id, values);
    } else {
      requestPost(values);
    }
  }

  handleStatus(enabled) {
    const { id, requestEnable, requestDisable } = this.props;

    if (enabled) {
      requestEnable(id);
    } else {
      requestDisable(id);
    }
  }

  renderFilter = (values, errors, index, arrayHelpers) => {
    const actionTypeFields = values.fields.filter(field => field.id);

    const actionTypeField = actionTypeFields.find(field => field.id === values.conditions[index].actionTypeFieldId);

    return (
      <div className="row attribute" key={index}>
        {index > 0 && <span className="and-label">and</span>}
        <button type="button" className="glyphbutton"
          title="Delete Filter" onClick={() => arrayHelpers.remove(index)}>
          <span className="btn-sm btn-danger link glyphicon glyphicon-minus pull-right" />
        </button>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.actionTypeFieldId`} className="control-label font-bold">
              Field
              <Field id={`conditions.${index}.actionTypeFieldId`}
                name={`conditions.${index}.actionTypeFieldId`}
                component={SelectField}
                options={Convert(actionTypeFields)}
                onChange={form => {
                  form.setFieldValue(`conditions.${index}.operator`, null);
                  form.setFieldValue(`conditions.${index}.value`, '');
                }}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].actionTypeFieldId
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.operator`} className="control-label font-bold">
              Operator
              <Field id={`conditions.${index}.operator`}
                name={`conditions.${index}.operator`}
                component={OperatorField}
                dataType={actionTypeField ? actionTypeField.dataType : null}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].operator
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`conditions.${index}.value`} className="control-label font-bold">
              Value
              <Field id={`conditions.${index}.value`}
                name={`conditions.${index}.value`}
                component={DataTypeField}
                dataType={actionTypeField ? actionTypeField.dataType : null}
                operator={values.conditions[index].operator}
                className={
                  errors.conditions && errors.conditions[index] && errors.conditions[index].value
                    ? 'form-control error'
                    : 'form-control'
                } />
            </label>
          </div>
        </div>
      </div>
    );
  };

  renderField = (values, errors, index, arrayHelpers) => {
    const { id } = values.fields[index];
    const allowEdit = !values.conditions.find(condition => condition.actionTypeFieldId === id);

    return (
      <div className="row attribute" key={index}>
        {allowEdit && (
          <button type="button" className="glyphbutton"
            title="Delete Field" onClick={() => arrayHelpers.remove(index)}>
            <span className="btn-sm btn-danger link glyphicon glyphicon-minus pull-right" />
          </button>
        )}
        <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`fields.${index}.name`} className="control-label font-bold">
              Name
              <Field id={`fields.${index}.name`}
                name={`fields.${index}.name`}
                className={
                  errors.fields && errors.fields[index] && errors.fields[index].name ? 'form-control error' : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-5 col-md-5 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor={`fields.${index}.description`} className="control-label font-bold">
              Description
              <Field id={`fields.${index}.description`}
                name={`fields.${index}.description`}
                value={values.fields[index].description || ''}
                className={
                  errors.fields && errors.fields[index] && errors.fields[index].description ? 'form-control error' : 'form-control'
                } />
            </label>
          </div>
        </div>
        <div className="col-lg-3 col-md-3 col-sm-12 col-xs-12">
          <div className="form-group form-group-lg">
            <label htmlFor="dataType" className="control-label font-bold">
              Data Type
              <Field id={`fields.${index}.dataType`}
                name={`fields.${index}.dataType`}
                component={SelectField}
                isDisabled={!allowEdit}
                options={ConvertToOptions(DataType)}
                className={
                  errors.fields && errors.fields[index] && errors.fields[index].dataType ? 'form-control error' : 'form-control'
                } />
            </label>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      id, actionType, requestDelete, requestSync,
    } = this.props;
    const { template, section } = this.state;

    return (
      <Formik onSubmit={values => this.handleSave(values)}
        innerRef={this.form}
        enableReinitialize
        validationSchema={Yup.object().shape({
          name: Yup.string().max(450).required(),
          description: Yup.string().nullable(),
          delayInHours: Yup.number().nullable(),
          integrationType: Yup.number().nullable(),
          integrationSettings: Yup.object()
            .when('integrationType', {
              is: IntegrationType.MZoneTrip,
              then: Yup.object().shape({
                apiUrl: Yup.string().url().required(),
                username: Yup.string().required(),
                password: Yup.string().required(),
              }),
            })
            .when('integrationType', {
              is: IntegrationType.MZonePeriodic,
              then: Yup.object().shape({
                apiUrl: Yup.string().url().required(),
                authUrl: Yup.string().url().required(),
                clientId: Yup.string().required(),
                clientSecret: Yup.string().required(),
                username: Yup.string().required(),
                password: Yup.string().required(),
                profileId: Yup.string().nullable(),
              }),
            })
            .when('integrationType', {
              is: IntegrationType.DInsightPeriodic,
              then: Yup.object().shape({
                apiUrl: Yup.string().url().required(),
                tenantName: Yup.string().required(),
                tenantApiKey: Yup.string().required(),
              }),
            })
            .when('integrationType', {
              is: IntegrationType.DInsightTrip,
              then: Yup.object().shape({
                apiUrl: Yup.string().url().required(),
                tenantName: Yup.string().required(),
                tenantApiKey: Yup.string().required(),
                profileId: Yup.string().required(),
              }),
            })
            .when('integrationType', {
              is: null,
              then: Yup.object().nullable(),
            }),
          fields: Yup.array().of(
            Yup.object().shape({
              name: Yup.string()
                .max(450)
                .required(),
              description: Yup.string().nullable(),
              dataType: Yup.number()
                .integer()
                .required(),
            }),
          ),
          conditions: Yup.array().of(
            Yup.object().shape({
              actionTypeFieldId: Yup.number()
                .integer()
                .required(),
              operator: Yup.number()
                .integer()
                .required(),
              value: Yup.string().required(),
            }),
          ),
          imageId: Yup.string().nullable(),
        })}
        initialValues={actionType || template}>
        {({ values, errors }) => (
          <Form>
            <div id="dataForm">
              <div className="row m-b-sm">
                <div className="col-md-12">
                  <span className="page-title hex-img">{id ? 'Edit Action Type' : 'New Action Type'}</span>
                  <span className="sub-heading hex-img">
                    Action Type is a definition of player action that can be imported into GZone
                  </span>
                  <div className="pageStyle">
                    <BackButton />
                    {id && actionType && actionType.integrationType && (
                      <button type="button"
                        className="delete-btn"
                        onClick={() => confirm().then(() => requestSync(id), NoCancel)}>
                        <i className="glyphicon glyphicon-refresh" />
                        <div className="hover-text">Resync</div>
                      </button>
                    )}
                    {id && <DeleteButton onClick={() => requestDelete(id)} />}
                    <SaveButton />
                  </div>
                </div>
              </div>
              <div className="row p-l-md">
                <div className="col-md-12">
                  <div className="panel panel-default hex">
                    <div className="octomask">
                      <ImageUpload imageIds={[values.imageId]} form={this.form}
                        single />
                    </div>
                    <div className="panel-heading" />
                    <div className="panel-body hex" id="scroller">
                      <div className="col-md-9">
                        <div className="row">
                          <div className="col-md-6 col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="name" className="control-label font-bold">
                                Name
                                <Field id="name"
                                  name="name"
                                  autoFocus
                                  className={errors.name ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        {!id && (
                          <div className="row">
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="integrationType" className="control-label font-bold">
                                  Integration
                                  <Field id="integrationType"
                                    name="integrationType"
                                    component={SelectField}
                                    options={ConvertToOptions(IntegrationType)}
                                    isClearable
                                    className={errors.integrationType ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                            <div className="col-md-6 col-xs-12">
                              <div className="form-group form-group-lg">
                                <label htmlFor="delayInHours" className="control-label font-bold">
                                  Delay
                                  <Field id="delayInHours"
                                    name="delayInHours"
                                    component={SelectField}
                                    options={ConvertToOptions(Delay)}
                                    isClearable
                                    className={errors.delayInHours ? 'form-control error' : 'form-control'} />
                                </label>
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row">
                          <div className="col-xs-12">
                            <div className="form-group form-group-lg">
                              <label htmlFor="description" className="control-label font-bold">
                                Description
                                <Field id="description"
                                  name="description"
                                  value={values.description || ''}
                                  className={errors.description ? 'form-control error' : 'form-control'} />
                              </label>
                            </div>
                          </div>
                        </div>
                        {values.integrationType && (
                          <>
                            <div className="form-subsection">
                              {(values.integrationType === IntegrationType.MZoneTrip || values.integrationType === IntegrationType.MZonePeriodic) && (
                                <h4>MZone Settings</h4>
                              )}
                              {(values.integrationType === IntegrationType.DInsightTrip || values.integrationType === IntegrationType.DInsightPeriodic) && (
                                <h4>DInsight Settings</h4>
                              )}
                              <div className="row">
                                <div className="col-xs-12">
                                  <div className="form-group form-group-lg">
                                    <label htmlFor="integrationSettings.apiUrl" className="control-label font-bold">
                                      API URL
                                      <Field id="integrationSettings.apiUrl"
                                        name="integrationSettings.apiUrl"
                                        className={
                                          errors.integrationSettings && errors.integrationSettings.apiUrl
                                            ? 'form-control error'
                                            : 'form-control'
                                        } />
                                    </label>
                                  </div>
                                </div>
                              </div>
                              {(values.integrationType === IntegrationType.MZonePeriodic) && (
                                <>
                                  <div className="row">
                                    <div className="col-xs-12">
                                      <div className="form-group form-group-lg">
                                        <label htmlFor="integrationSettings.authUrl" className="control-label font-bold">
                                          Authentication URL
                                          <Field id="integrationSettings.authUrl"
                                            name="integrationSettings.authUrl"
                                            className={
                                              errors.integrationSettings && errors.integrationSettings.authUrl
                                                ? 'form-control error'
                                                : 'form-control'
                                            } />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                      <div className="form-group form-group-lg">
                                        <label htmlFor="integrationSettings.clientId" className="control-label font-bold">
                                          Client ID
                                          <Field id="integrationSettings.clientId"
                                            name="integrationSettings.clientId"
                                            autoComplete="off"
                                            className={
                                              errors.integrationSettings && errors.integrationSettings.clientId
                                                ? 'form-control error'
                                                : 'form-control'
                                            } />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                      <div className="form-group form-group-lg">
                                        <label htmlFor="integrationSettings.clientSecret" className="control-label font-bold">
                                          Client Secret
                                          <Field id="integrationSettings.clientSecret"
                                            name="integrationSettings.clientSecret"
                                            type="password"
                                            autoComplete="new-password"
                                            className={
                                              errors.integrationSettings && errors.integrationSettings.clientSecret
                                                ? 'form-control error'
                                                : 'form-control'
                                            } />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {(values.integrationType === IntegrationType.MZoneTrip || values.integrationType === IntegrationType.MZonePeriodic) && (
                                <>
                                  <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                      <div className="form-group form-group-lg">
                                        <label htmlFor="integrationSettings.username" className="control-label font-bold">
                                          Username
                                          <Field id="integrationSettings.username"
                                            name="integrationSettings.username"
                                            autoComplete="off"
                                            className={
                                              errors.integrationSettings && errors.integrationSettings.username
                                                ? 'form-control error'
                                                : 'form-control'
                                            } />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                      <div className="form-group form-group-lg">
                                        <label htmlFor="integrationSettings.password" className="control-label font-bold">
                                          Password
                                          <Field id="integrationSettings.password"
                                            name="integrationSettings.password"
                                            type="password"
                                            autoComplete="new-password"
                                            className={
                                              errors.integrationSettings && errors.integrationSettings.password
                                                ? 'form-control error'
                                                : 'form-control'
                                            } />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {(values.integrationType === IntegrationType.DInsightTrip || values.integrationType === IntegrationType.DInsightPeriodic) && (
                                <>
                                  <div className="row">
                                    <div className="col-md-6 col-xs-12">
                                      <div className="form-group form-group-lg">
                                        <label htmlFor="integrationSettings.tenantName" className="control-label font-bold">
                                          Tenant Name
                                          <Field id="integrationSettings.tenantName"
                                            name="integrationSettings.tenantName"
                                            className={
                                              errors.integrationSettings && errors.integrationSettings.tenantName
                                                ? 'form-control error'
                                                : 'form-control'
                                            } />
                                        </label>
                                      </div>
                                    </div>
                                    <div className="col-md-6 col-xs-12">
                                      <div className="form-group form-group-lg">
                                        <label htmlFor="integrationSettings.tenantApiKey" className="control-label font-bold">
                                          Tenant API Key
                                          <Field id="integrationSettings.tenantApiKey"
                                            name="integrationSettings.tenantApiKey"
                                            className={
                                              errors.integrationSettings && errors.integrationSettings.tenantApiKey
                                                ? 'form-control error'
                                                : 'form-control'
                                            } />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                              {(values.integrationType === IntegrationType.MZonePeriodic || values.integrationType === IntegrationType.DInsightTrip) && (
                                <>
                                  <div className="row">
                                    <div className="col-xs-12">
                                      <div className="form-group form-group-lg">
                                        <label htmlFor="integrationSettings.profileId" className="control-label font-bold">
                                          Profile ID
                                          <Field id="integrationSettings.profileId"
                                            name="integrationSettings.profileId"
                                            className={
                                              errors.integrationSettings && errors.integrationSettings.profileId
                                                ? 'form-control error'
                                                : 'form-control'
                                            } />
                                        </label>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </>
                        )}
                        {id && (
                          <div className="profile-selector">
                            <div className={section === 'fields' ? 'active' : ''}>
                              <button type="button" title="Fields"
                                className="btn-profile"
                                onClick={() => this.setState({ section: 'fields' })}>
                                Fields {values.fields.length}
                              </button>
                            </div>
                            <div className={section === 'conditions' ? 'active' : ''}>
                              <button type="button" title="Conditions"
                                className="btn-profile"
                                onClick={() => this.setState({ section: 'conditions' })}>
                                Conditions {values.conditions.length}
                              </button>
                            </div>
                          </div>
                        )}
                        {(id || !values.integrationType) && section === 'fields' && (
                          <FieldArray name="fields"
                            render={arrayHelpers => (
                              <div className="form-subsection">
                                <h4>Fields</h4>
                                <button type="button"
                                  className="glyphbutton"
                                  title="Add"
                                  onClick={() => arrayHelpers.push({
                                    name: '',
                                    description: '',
                                    dataType: '',
                                  })}>
                                  <span className="btn-sm btn-success  pull-right link glyphicon glyphicon-plus" />
                                </button>
                                <div className="form-group form-group-lg">
                                  <label htmlFor="fields" className="control-label font-bold">
                                    Fields must match incomming data and will always be validated
                                  </label>
                                </div>
                                {values.fields
                                    && values.fields.map((obj, index) => this.renderField(values, errors, index, arrayHelpers))}
                              </div>
                            )} />
                        )}
                        {id && section === 'conditions' && (
                          <FieldArray name="conditions"
                            render={arrayHelpers => (
                              <div className="form-subsection">
                                <h4>Conditions</h4>
                                <button type="button"
                                  className="glyphbutton"
                                  title="Add"
                                  onClick={() => arrayHelpers.push({
                                    actionTypeFieldId: '',
                                    operator: null,
                                    value: '',
                                  })}>
                                  <span className="btn-sm btn-success  pull-right link glyphicon glyphicon-plus" />
                                </button>
                                <div className="form-group form-group-lg">
                                  <label htmlFor="conditions" className="control-label font-bold">
                                    Only actions that match all conditions will be imported and processed
                                  </label>
                                </div>
                                {values.conditions
                                    && values.conditions.map((obj, index) => this.renderFilter(values, errors, index, arrayHelpers))}
                              </div>
                            )} />
                        )}
                      </div>
                      {id
                        && actionType && (
                        <div className="col-md-3 form-additional-info">
                          <label>
                            Created
                            <div>{date.long(actionType.createdOn)}</div>
                          </label>
                          {actionType.integrationType && (
                            <label>
                              Integration
                              <div>{IntegrationType[actionType.integrationType]}</div>
                            </label>
                          )}
                          <label>
                            Delay
                            <div>{actionType.delayInHours} hours</div>
                          </label>
                          {
                            actionType.integrationState && actionType.integrationState.lastTripId
                            && (
                              <label>
                                Last Trip ID (MZone)
                                <div>{actionType.integrationState.lastTripId}</div>
                              </label>
                            )
                          }
                          {
                            actionType.integrationState && actionType.integrationState.lastTripCreatedOn
                            && (
                              <label>
                                Last Trip Created (MZone)
                                <div>{date.long(actionType.integrationState.lastTripCreatedOn)}</div>
                              </label>
                            )
                          }
                          {
                            actionType.integrationState && actionType.integrationState.lastTripUpdatedOn
                            && (
                              <label>
                                Last Trip Updated (DInsight)
                                <div>{date.long(actionType.integrationState.lastTripUpdatedOn)}</div>
                              </label>
                            )
                          }
                          {
                            actionType.integrationState && actionType.integrationState.lastProcessedTenantDate
                            && (
                              <label>
                                Last Processed (DInsight)
                                <div>{date.short(actionType.integrationState.lastProcessedTenantDate)}</div>
                              </label>
                            )
                          }
                          {
                            actionType.integrationState && actionType.integrationState.lastRunTime
                            && (
                              <label>
                                Last Run
                                <div>{date.long(actionType.integrationState.lastRunTime)}</div>
                              </label>
                            )
                          }
                          <label htmlFor="status-switch">
                            Disabled
                            <div>
                              <Switch id="status-switch"
                                onChange={() => this.handleStatus(actionType.isDisabled)}
                                checked={actionType.isDisabled}
                                checkedChildren={<i className="glyphicon glyphicon-ok" />}
                                unCheckedChildren={<i className="glyphicon glyphicon-remove" />} />
                            </div>
                          </label>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
}

ActionTypeEdit.propTypes = {
  id: PropTypes.number,
  actionType: PropTypes.shape({ id: PropTypes.number, name: PropTypes.string, integrationType: PropTypes.number }),
  requestGet: PropTypes.func.isRequired,
  requestPost: PropTypes.func.isRequired,
  requestPut: PropTypes.func.isRequired,
  requestEnable: PropTypes.func.isRequired,
  requestDisable: PropTypes.func.isRequired,
  requestDelete: PropTypes.func.isRequired,
  requestSync: PropTypes.func.isRequired,
};

ActionTypeEdit.defaultProps = {
  id: null,
  actionType: null,
};

export default connect(
  (state, ownProps) => ({
    id: helper.getIdFromProps(ownProps),
    actionType: state.actionType.details[helper.getIdFromProps(ownProps)],
  }),
  dispatch => bindActionCreators({ ...actionCreators }, dispatch),
)(ActionTypeEdit);
